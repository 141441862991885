.catchy-banner {
    animation: flashy 1s infinite alternate;
  }
  
  @keyframes flashy {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.3);
    }
  }